import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/phmeter');
}

async function getById(id) {
    return await client.get(`/phmeter/${id}`);
}

function update(id, data) {
    return client.put(`/phmeter/${id}`, { json: data });
}

function create(data) {
    return client.post(`/phmeter`, { json: data });
}

function deletePHMeter(id) {
    return client.delete(`/phmeter/${id}`);
}

function runManualSpike(id, data) {
    return client.put(`/phmeter/${id}/manual-spike`, { json: data });
}

function runManualDrop(id, data) {
    return client.put(`/phmeter/${id}/manual-drop`, { json: data });
}

export { getAll, getById, update, create, deletePHMeter, runManualSpike, runManualDrop };
