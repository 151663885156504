import { Dialog, DialogTitle, DialogActions, Button, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const DeleteDialog = ({ open, name, onConfirm, onCancel }) => {
    const useStyles = makeStyles(theme => ({
        dialogPaper: {
            '& .MuiPaper-root': {
                padding: theme.spacing(2),
                border: '1px solid red',
            },
        },
        dialogActions: {
            padding: theme.spacing(1),
        },
        deleteButton: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
        },
    }));

    const classes = useStyles();

    return (
        <Dialog className={classes.dialogPaper} open={open} onClose={onCancel} maxWidth="sm">
            <DialogTitle>
                <Typography variant="subtitel2">Are you sure you want to delete the device ?</Typography>
            </DialogTitle>
            <DialogActions style={{ display: 'grid', gridGap: 10, gridTemplateColumns: '1fr 1fr' }}>
                <Button
                    className={clsx(classes.dialogActions, classes.deleteButton)}
                    variant="contained"
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
                <Button className={classes.dialogActions} variant="contained" onClick={onCancel} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
