import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/rswsn01');
}

async function getById(id) {
    return await client.get(`/rswsn01/${id}`);
}

function update(id, data) {
    return client.put(`/rswsn01/${id}`, { json: data });
}

function create(data) {
    return client.post(`/rswsn01`, { json: data });
}

function deleteRSWSN01(id) {
    return client.delete(`/rswsn01/${id}`);
}

export { getAll, getById, update, create, deleteRSWSN01 };
