import { SensorType } from '../constants';

const sensorTypeToLabel = type => {
    switch (type) {
        case SensorType.NotSupported:
            return 'Generic';
        case SensorType.Truebner_SMT100:
            return 'Truebner_SMT100';
        default:
            return 'Unknown type';
    }
};

export default sensorTypeToLabel;
