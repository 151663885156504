export const DeviceType = Object.freeze({
    IMA01: 1,
    WaterMeter: 2,
    MainPump: 3,
    FertiMeter: 4,
    SETM221: 5,
    Valve: 6,
    PhMeter: 7,
    MJBXS: 8,
    EcMeter: 9,
    WS200: 10,
    RSWSN01: 12,
    GenericSensor: 13
});

export const SensorParameterType = Object.freeze({
    NotSupported: 0,
    SoilMoisture: 1,
    SoilTemperature: 2,
});

export const SensorType = Object.freeze({
    NotSupported: 0,
    Truebner_SMT100: 1,
});

export const BaudRates = [9600, 19200, 38400, 57600];

export const WatchDogErrorStatus = Object.freeze({
    OK: 0,
    Triggered: 1,
});


export const SensorParametersTemplates = Object.freeze({
    [SensorType.Truebner_SMT100]: [
        {
            name: 'Soil temperature',
            register: 0,
            type: SensorParameterType.SoilTemperature
        },
        {
            name: 'Soil moisture',
            register: 1,
            type: SensorParameterType.SoilMoisture
        }
    ]
})