import { SensorParameterType } from '../constants';

const sensorParameterTypeToLabel = (type) => {
    switch (type) {
        case SensorParameterType.SoilMoisture:
            return 'Soil moisture';
        case SensorParameterType.SoilTemperature:
            return 'Soil temperature';
        case SensorParameterType.NotSupported:
            return 'Generic';
        default:
            return 'Unknown type';
    }
};

export default sensorParameterTypeToLabel;
